import user1Image from '../assets/img/users/100_1.jpg';
import user2Image from '../assets/img/users/100_2.jpg';
import user3Image from '../assets/img/users/100_3.jpg';
import user4Image from '../assets/img/users/100_4.jpg';
import user5Image from '../assets/img/users/100_5.jpg';

export const notificationsData = [
  {
    id: 1,
    avatar: user1Image,
    message: 'David sent you a message',
    date: '3 min ago',
  },
];
