import React from 'react';
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import componentQueries from 'react-component-queries';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { EmptyLayout,LayoutRoute, MainLayout } from './components/Layout';
import PrivateRoute from "./components/private-route/PrivateRoute";
import GAListener from './components/GAListener';
import PageSpinner from './components/PageSpinner';
import AuthForm from './components/AuthForm';
import NotFound from "./components/Layout/NotFound";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import store from "./store";
import './styles/reduction.scss';
import './styles/dashboard.css';
import { toast, ToastContainer} from "react-toastify";

const userlist = React.lazy(() => import('./components/pages/Userlist'));
const Settings= React.lazy(() => import('./components/pages/Settings'));
const SwapPending = React.lazy(() => import('./components/pages/SwapPending'));
const ApproveSwapping = React.lazy(() => import('./components/pages/ApproveSwapping'));
const ExchangeList = React.lazy(() => import('./components/pages/ExchangeList'));
const WithdrawList = React.lazy(() => import('./components/pages/WithdrawList'));
const StakeList = React.lazy(() => import('./components/pages/StakeList'));
const ResetPassword = React.lazy(() => import('./components/pages/ResetPassword'));
const rewardtoken = React.lazy(() => import('./components/pages/RewardToken'));
//Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends React.Component {
  render() {
    return (
       <Provider store={store}>
         <Router basename={"/oroadmin"}>
          
            <GAListener>
            <ToastContainer/>
              <Switch>
                <Route exact 
                path={["/","/userlist","/rewardtoken", "/exchange-pending", "/approved-exchange","/exchange-list","/withdraw-list","/stake-list","/reset-password"]
                }>
                  <MainLayout breakpoint={this.props.breakpoint}>
                    <React.Suspense fallback={<PageSpinner />}>
                     <PrivateRoute exact path="/userlist" component={userlist} />
                     <PrivateRoute exact path="/rewardtoken" component={rewardtoken} />
                     <PrivateRoute exact path="/exchange-list" component={ExchangeList} />
                     <PrivateRoute exact path="/withdraw-list" component={WithdrawList} />
                     <PrivateRoute exact path="/stake-list" component={StakeList} />
                     <PrivateRoute exact path="/reset-password" component={ResetPassword} />
                     {/* <PrivateRoute exact path="/approved-exchange" component={ApproveSwapping} /> */}
                     <PrivateRoute exact path="/" component={userlist} />
                    </React.Suspense>
                  </MainLayout>
                </Route>
                <Route exact path={["/login"]}>
                  <EmptyLayout breakpoint={this.props.breakpoint}>
                  <Route exact path="/" component={AuthForm}/>  
                    <Route exact path="/login" component={AuthForm}/>
                  </EmptyLayout>
                </Route>
              <Switch>
                <Route exact path="*" component={NotFound} />
              </Switch>
              </Switch>
            </GAListener>
       
              </Router>
      </Provider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
