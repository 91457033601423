import logo200Image from '../assets/img/logo/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label, Row, Col, Card } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../actions/authActions";
import classnames from "classnames";
//import '../styles/dashboard.css';

class AuthForm extends React.Component {

  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextPropsnextPropsnextProps',nextProps)

    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/userlist");
    }
    if (nextProps.errors && nextProps.errors.errors) {
        this.setState({
          errors: nextProps.errors.errors
        });
      }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };

  this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

render() {
    const { errors } = this.state;

    return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
       <Col md={6} lg={4}>
          <Card body>
      <Form onSubmit={this.onSubmit}   >
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              
              style={{ width: 120, height: 75, cursor: 'pointer' }}
              alt="logo"
            />
          </div>
        <FormGroup>
           <label htmlFor="email">Email</label>
            <input
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
              className={classnames("form-control", {
                  invalid: errors.email
              })}
            />
             <span className="text-danger">{errors.email}</span>
        </FormGroup>
        <FormGroup>
           <label htmlFor="email">Password</label>
           <input
                onChange={this.onChange}
                value={this.state.password}
                error={errors.password}
                id="password"
                type="password"
                className={classnames("form-control", {
                    invalid: errors.password
                })}
            />
            <span className="text-danger">{errors.password}</span>
        </FormGroup>
        <hr />
        <div className="text-center pt-1">
        <Button
          size="lg"
          className="text-center pt-1"
          type="submit" >Login
        </Button>
        </div>

        {/* <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
              <a href="#login" >Forgot Password</a>

          </h6>
        </div> */}
      </Form>
      </Card>
        </Col>
      </Row>
    );
  }
}
AuthForm.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { loginUser }
)(AuthForm);
