import logo200Image from '../../assets/img/logo/logo.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from '../../components/SourceLink';
import React from 'react';
import {
  MdDashboard,
  MdAccountCircle,
  MdAccountBalanceWallet,
  MdSettings,
  MdAddShoppingCart
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [
  { to: '/userlist', name: 'User List', exact: true, Icon: MdSettings },
  { to: '/rewardtoken', name: 'Reward Token', exact: true, Icon: MdSettings },
  // { to: '/exchange-pending', name: 'Exchange request', exact: true, Icon: MdAccountBalanceWallet },
  // { to: '/approved-exchange', name: 'Approved exchange', exact: true, Icon: MdAccountBalanceWallet }
  // { to: '/exchange-list', name: 'Exchange List', exact: true, Icon: MdAccountBalanceWallet },
  // { to: '/withdraw-list', name: 'Withdraw List', exact: true, Icon: MdAccountBalanceWallet },
  // { to: '/stake-list', name: 'Staking List', exact: true, Icon: MdAccountBalanceWallet },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="160"
                height="110"
                className="pr-2"
                alt=""
              />
              {1==2 && <span className="text-white">
                TRX
              </span>}
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                {name !="Don Coin" &&
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
                }
                {name =="Don Coin" &&
                
                <span style={{color:'white', "font-size": "39px"}} className="doncoinlist">{name}</span>
                
                }
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
